import React from "react"
import Questionnaire from "./Questionnaire/Questionnaire"
import styles from "./CustomQuestionaires.module.scss"
import {
  AssessmentType,
  CUSTOM_ASSESSMENT_QUESTIONNAIRES,
  DiscussionSteps,
  QuestionnaireDialogue
} from "@limbic/types"
import { FF, hasFF } from "../../../../../../../../featureFlags"

export function getEnabledCustomAssessmentQuestionnaires() {
  return Object.entries(CUSTOM_ASSESSMENT_QUESTIONNAIRES).reduce(
    (obj, [assessmentType, options]) => ({
      ...obj,
      [assessmentType]: options.filter(q => {
        switch (q) {
          case DiscussionSteps.HAM_A:
            return hasFF(FF.AC906_HAM_A)
          case DiscussionSteps.HAM_D:
            return hasFF(FF.AC905_HAM_D)
          default:
            return true
        }
      })
    }),
    {} as Record<AssessmentType, QuestionnaireDialogue[]>
  )
}

// this is done purely for testing purposes
// so we can mock the flags and assert the
// correct options are returned each time
const ENABLED_CUSTOM_ASSESSMENT_QUESTIONNAIRES = getEnabledCustomAssessmentQuestionnaires()

interface CustomQuestionnairesProps {
  handleCustomQuestionnaire: (type: AssessmentType, questionnaire: QuestionnaireDialogue) => void
}

const CustomQuestionnaires = ({
  handleCustomQuestionnaire
}: CustomQuestionnairesProps): JSX.Element => {
  return (
    <div>
      <div className={styles.custom_Questionnaire_heading}>
        Alternate Questionnaires for Assessments
      </div>
      {Object.entries(ENABLED_CUSTOM_ASSESSMENT_QUESTIONNAIRES).map(([assessmentType, options]) => (
        <div key={assessmentType} className={styles.custom_Questionnaire_Container}>
          <Questionnaire
            assessmentType={assessmentType as AssessmentType}
            options={options}
            onSelect={handleCustomQuestionnaire}
          />
        </div>
      ))}
    </div>
  )
}

export default CustomQuestionnaires
