import React, { ChangeEvent } from "react"
import { observer } from "mobx-react"
import styles from "./CollectPhoneNumber.module.scss"
import Button from "../../../../../Button"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"
import { ChatFlowsEnum, COUNTRIES_MAP, ICountry } from "@limbic/types"
import SolidDivider from "../../../../../SolidDivider"
import { Autocomplete, Chip, TextField, Typography } from "@mui/material"
import FormControlWithCheckboxControlledInput from "../FormControlWithCheckBox/FormControlWithCheckboxControlledInput"
import { getArraysAreEqual } from "../../../../../../../utils/array"

const DEFAULT_COUNTRY_CODE = process.env.REACT_APP_DEFAULT_PHONE_NUMBER_REGION_CODE ?? "GB"
const COUNTRY_CODES = Object.keys(COUNTRIES_MAP)

function CollectPhoneNumber(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages
  const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER]
  const useCustomKeys =
    !!currentSettings?.phoneNumberKey ||
    !!currentSettings?.smsConsentKey ||
    !!currentSettings?.voicemailConsentKey

  const hasAllCountryCodes = React.useMemo(
    () => getArraysAreEqual(currentSettings?.supportedCountries ?? [], COUNTRY_CODES),
    [currentSettings?.supportedCountries]
  )

  const updateState = data => nodeEditorStore.updateChatFlowState(data)

  const onForceMobileClick = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
          ...currentSettings,
          forceMobile: value || undefined, // simply removing when false
          forceLandline: value ? false : currentSettings?.forceLandline // disabling when true
        }
      }
    })
  }

  const onForceLandlineClick = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
          ...currentSettings,
          forceMobile: value ? false : currentSettings?.forceMobile, // disabling when true
          forceLandline: value || undefined, // simply removing when false
          requestSMSConsent: value ? false : currentSettings?.requestSMSConsent // disabling when true
        }
      }
    })
  }

  const onAllCountryCodesClick = () => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: { ...currentSettings }
    }

    if (!hasAllCountryCodes) {
      const supportedCountries = [...new Set([DEFAULT_COUNTRY_CODE, ...COUNTRY_CODES])]
      newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] = { supportedCountries }
    } else {
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER].supportedCountries
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onAppendCountryCodesClick = () => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: { ...currentSettings }
    }

    const supportedCountries = [
      ...new Set([...(currentSettings?.supportedCountries ?? []), ...COUNTRY_CODES])
    ]
    newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] = { supportedCountries }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onCountryCodesChange = (_: React.SyntheticEvent, countries: ICountry[]) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
        ...currentSettings,
        supportedCountries: countries.map(c => c.isoAlpha2Code)
      }
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onCustomiseStateKeysClick = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: { ...currentSettings }
    }

    // if value is true it means we're enabling custom keys
    // so, we need to reset the entries to the default values
    // otherwise remove the entries entirely
    if (value) {
      newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] = {
        ...currentSettings,
        phoneNumberKey: "phoneNumber",
        smsConsentKey: "canSendTextMessagesToPhoneNumber",
        voicemailConsentKey: "canLeaveVoicemailToPhoneNumber"
      }
    } else {
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER].phoneNumberKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER].smsConsentKey
      delete newChatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER].voicemailConsentKey
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditPhoneNumberKey = (event: ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
        ...currentSettings,
        phoneNumberKey: event.target.value || undefined // do not store an empty string
      }
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditSMSConsentKey = (event: ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
        ...currentSettings,
        smsConsentKey: event.target.value || undefined // do not store an empty string
      }
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditVoicemailConsentKey = (event: ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
        ...currentSettings,
        voicemailConsentKey: event.target.value || undefined // do not store an empty string
      }
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  const onEditCallConsentKey = (event: ChangeEvent<HTMLInputElement>) => {
    const currentSettings = chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER] ?? {}
    const newChatFlowSettings = {
      ...chatFlowSettings,
      [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: {
        ...currentSettings,
        callConsentKey: event.target.value || undefined // do not store an empty string
      }
    }

    updateState({ chatFlowSettings: newChatFlowSettings })
  }

  return (
    <>
      <Typography className={styles.settingsTitle}>Phone number question settings:</Typography>

      <MessageList
        messages={messages?.askPhoneNumber ?? []}
        messageType="askPhoneNumber"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askPhoneNumber}
        placeholder="Type a message here..."
        name="askPhoneNumber"
        updateState={updateState}
        messages={messages?.askPhoneNumber ?? []}
      />

      <FormControlWithCheckboxControlledInput
        name="forceMobile"
        value={currentSettings?.forceMobile ?? false}
        label="Force Input to be a mobile phone"
        labelPlacement="start"
        onToggle={onForceMobileClick}
      />

      <FormControlWithCheckboxControlledInput
        name="forceLandline"
        value={currentSettings?.forceLandline ?? false}
        label="Force Input to be a landline phone"
        labelPlacement="start"
        onToggle={onForceLandlineClick}
      />

      <SolidDivider />

      <Typography className={styles.settingsTitle}>Permission question settings:</Typography>

      <MessageList
        messages={messages?.askCanIContactYouOnPhoneNumber ?? []}
        messageType="askCanIContactYouOnPhoneNumber"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askCanIContactYouOnPhoneNumber}
        placeholder="Type a message here..."
        name="askCanIContactYouOnPhoneNumber"
        updateState={updateState}
        messages={messages?.askCanIContactYouOnPhoneNumber ?? []}
      />

      <FormControlWithCheckBox
        name="requestVoicemailConsent"
        label="Request voicemail consent"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="requestSMSConsent"
        disabled={currentSettings?.forceLandline}
        label="Request SMS consent"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="requestCallConsent"
        label="Request Call consent"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="shouldCheckConsentsByDefault"
        label="Consents should be checked initially"
        labelPlacement="start"
        updateState={updateState}
      />

      <SolidDivider />

      <Typography className={styles.settingsTitle}>Supported Country Codes:</Typography>

      <p>{(currentSettings?.supportedCountries ?? []).length} country codes selected</p>

      <Autocomplete
        className={styles.autocompleteOptions}
        multiple
        id="country-codes"
        onChange={onCountryCodesChange}
        options={Object.values(COUNTRIES_MAP)}
        limitTags={5}
        getOptionLabel={(country: ICountry) =>
          `[${country.isoAlpha2Code}] ${country.name} (+${country.phonePrefix})`
        }
        renderTags={(value: readonly ICountry[], getTagProps) =>
          value.map((c: ICountry, index: number) => (
            <Chip
              variant="outlined"
              label={c.name}
              {...getTagProps({ index })}
              className={styles.optionChip}
            />
          ))
        }
        value={(currentSettings?.supportedCountries ?? []).map(code => COUNTRIES_MAP[code])}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Country Codes"
            placeholder="Type the country codes"
          />
        )}
      />

      {!!currentSettings?.supportedCountries?.length && !hasAllCountryCodes ? (
        <Button onClick={onAppendCountryCodesClick}>Append All</Button>
      ) : (
        <Button onClick={onAllCountryCodesClick}>
          {hasAllCountryCodes ? "Remove" : "Add"} All
        </Button>
      )}

      <SolidDivider />

      <Typography className={styles.settingsTitle}>Advanced Settings:</Typography>

      <FormControlWithCheckboxControlledInput
        name="customiseStateKeys"
        value={useCustomKeys}
        label="Edit state keys"
        labelPlacement="start"
        onToggle={onCustomiseStateKeysClick}
      />

      {useCustomKeys && (
        <>
          <div className={styles.keyInputContainer}>
            <Typography>Phone Number</Typography>
            <input
              className={styles.keyInput}
              value={
                chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER]?.phoneNumberKey ??
                "phoneNumber"
              }
              placeholder="phoneNumber"
              onChange={onEditPhoneNumberKey}
            />
          </div>

          <div className={styles.keyInputContainer}>
            <Typography>SMS Consent</Typography>
            <input
              className={styles.keyInput}
              value={
                chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER]?.smsConsentKey ??
                "canSendTextMessagesToPhoneNumber"
              }
              placeholder="canSendTextMessagesToPhoneNumber"
              onChange={onEditSMSConsentKey}
            />
          </div>

          <div className={styles.keyInputContainer}>
            <Typography>Voicemail Consent</Typography>
            <input
              className={styles.keyInput}
              value={
                chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER]?.voicemailConsentKey ??
                "canLeaveVoicemailToPhoneNumber"
              }
              placeholder="canLeaveVoicemailToPhoneNumber"
              onChange={onEditVoicemailConsentKey}
            />
          </div>

          <div className={styles.keyInputContainer}>
            <Typography>Call Consent</Typography>
            <input
              className={styles.keyInput}
              value={
                chatFlowSettings[ChatFlowsEnum.COLLECT_PHONE_NUMBER]?.callConsentKey ??
                "canCallPhoneNumber"
              }
              placeholder="canCallPhoneNumber"
              onChange={onEditCallConsentKey}
            />
          </div>
        </>
      )}
    </>
  )
}

export default observer(CollectPhoneNumber)
