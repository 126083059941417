import z from "zod"
import * as self from "./featureFlags"

export enum FF {
  AC903_BDI_2 = "AC903_BDI_2",
  AC904_PSWQ = "AC904_PSWQ",
  AC905_HAM_D = "AC905_HAM_D",
  AC906_HAM_A = "AC906_HAM_A",
  AC907_US_ADDRESS = "AC907_US_ADDRESS",
  AC908_STANDALONE_QUESTIONNAIRE = "AC908_STANDALONE_QUESTIONNAIRE"
}

const schema: Record<FF, z.ZodBoolean> = {
  [FF.AC903_BDI_2]: z.boolean(),
  [FF.AC904_PSWQ]: z.boolean(),
  [FF.AC905_HAM_D]: z.boolean(),
  [FF.AC906_HAM_A]: z.boolean(),
  [FF.AC907_US_ADDRESS]: z.boolean(),
  [FF.AC908_STANDALONE_QUESTIONNAIRE]: z.boolean()
}

export const featureFlagsGuard = z.object(schema).strict()
export type FeatureFlags = z.infer<typeof featureFlagsGuard>

const regionFlags = require("./region-flags.json") ?? {}
const localFlags: Record<FF, boolean> = {
  [FF.AC903_BDI_2]: false,
  [FF.AC904_PSWQ]: false,
  [FF.AC905_HAM_D]: false,
  [FF.AC906_HAM_A]: false,
  [FF.AC907_US_ADDRESS]: false,
  [FF.AC908_STANDALONE_QUESTIONNAIRE]: false
}

const featureFlags = featureFlagsGuard.parse({
  ...localFlags,
  ...regionFlags
})

export function hasFF(key: FF): boolean {
  // this happens on purpose so we can mock the function in tests
  // with different combinations of flags
  // noinspection PointlessBooleanExpressionJS
  return self.getAllFeatureFlags()[key] === true
}

export function getAllFeatureFlags(): FeatureFlags {
  // sanity check to ensure that the flags are not mutated
  const copy = featureFlagsGuard.parse(JSON.parse(JSON.stringify(featureFlags)))
  Object.freeze(copy)
  return copy
}
