import React from "react"
import { observer } from "mobx-react"
import FormControlWithCheckBox from "../../components/FormControlWithCheckBox"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import SolidDivider from "../../../../../SolidDivider"
import {
  AVAILABLE_QUESTIONNAIRES_PRESET_FLOW,
  ChatFlowsEnum,
  DiscussionSteps,
  QuestionnaireDialogue
} from "@limbic/types"
import { Autocomplete, TextField } from "@mui/material"
import { FF, hasFF } from "../../../../../../../featureFlags"

export function getQuestionnaireOptions() {
  return AVAILABLE_QUESTIONNAIRES_PRESET_FLOW.filter(q => {
    switch (q.value) {
      case DiscussionSteps.BDI2:
        return hasFF(FF.AC903_BDI_2)
      case DiscussionSteps.PSWQ:
        return hasFF(FF.AC904_PSWQ)
      default:
        return true
    }
  })
}

// this is done purely for testing purposes
// so we can mock the flags and assert the
// correct options are returned each time
const QUESTIONNAIRE_OPTIONS = getQuestionnaireOptions()

function Questionnaires(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const currentSettings = chatFlowSettings?.[ChatFlowsEnum.QUESTIONNAIRES]

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleQuestionnaire = (questionnaire: QuestionnaireDialogue) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.QUESTIONNAIRES]: {
          ...currentSettings,
          questionnaire: questionnaire
        }
      }
    })
  }

  const currentQuestionnaire = currentSettings?.questionnaire
    ? QUESTIONNAIRE_OPTIONS.find(q => q.value === currentSettings?.questionnaire)
    : null

  return (
    <>
      <Autocomplete
        renderInput={params => (
          <TextField
            {...params}
            label="Questionnaire"
            placeholder="Please select a questionnaire to add"
          />
        )}
        options={QUESTIONNAIRE_OPTIONS}
        value={currentQuestionnaire}
        onChange={(_, selectedOption) =>
          handleQuestionnaire(selectedOption?.value as QuestionnaireDialogue)
        }
      />
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldUpdateReferral"
        label="Enable updating the referral data as soon as the question is answered"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(Questionnaires)
