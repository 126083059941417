// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uxXiW47dYmLQOD5_kjkG{text-align:right;padding:0 1em;font-style:italic}.heLAll2S6eLKjHC1VGhD{margin:.5em 0 1em 0}.EzUq0dlh62O3e6MaPMaf{margin:.5em 0 1em 0}.jgzjlbgccRUt1y1navjl{margin-bottom:1em;font-style:italic;color:darkred}`, "",{"version":3,"sources":["webpack://./src/app/components/FlowEditor/components/ChatFlowEditor/components/AdministerCSSRS/AdministerCSSRS.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,aAAA,CACA,iBAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,iBAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":[".shouldSendRiskEmailCaption {\n  text-align: right;\n  padding: 0 1em;\n  font-style: italic;\n}\n\n.headingText {\n  margin: 0.5em 0 1em 0;\n}\n\n.questionText {\n  margin: 0.5em 0 1em 0;\n}\n\n.questionCaption {\n  margin-bottom: 1em;\n  font-style: italic;\n  color: darkred;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shouldSendRiskEmailCaption": `uxXiW47dYmLQOD5_kjkG`,
	"headingText": `heLAll2S6eLKjHC1VGhD`,
	"questionText": `EzUq0dlh62O3e6MaPMaf`,
	"questionCaption": `jgzjlbgccRUt1y1navjl`
};
export default ___CSS_LOADER_EXPORT___;
