import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material/"
import styles from "./Date.module.scss"
import { useNodeEditorStore, useSettingsStore } from "../../../../../../../context/rootStoreContext"
import { EditorType, IPromptSettings } from "../../../../../models/NodeEditors"
import { FormControl } from "@mui/material"
import React, { useEffect, useState } from "react"

const isValidDateInputFormat = (format: string): boolean => {
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape
  const validDateFormatRegex = /^(?:DD[\s\/\-\.,]*MM[\s\/\-\.,]*YYYY|DD[\s\/\-\.,]*YYYY[\s\/\-\.,]*MM|MM[\s\/\-\.,]*DD[\s\/\-\.,]*YYYY|MM[\s\/\-\.,]*YYYY[\s\/\-\.,]*DD|YYYY[\s\/\-\.,]*DD[\s\/\-\.,]*MM|YYYY[\s\/\-\.,]*MM[\s\/\-\.,]*DD)$/

  return validDateFormatRegex.test(format)
}

const isValidDateDisplayFormat = (format: string): boolean => {
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape
  const validDateFormatRegex = /^(?:DD[\s,\/\.\-]*(MM|MMM)[\s,\/\.\-]*YYYY|DD[\s,\/\.\-]*YYYY[\s,\/\.\-]*(MM|MMM)|(MM|MMM)[\s,\/\.\-]*DD[\s,\/\.\-]*YYYY|(MM|MMM)[\s,\/\.\-]*YYYY[\s,\/\.\-]*DD|YYYY[\s,\/\.\-]*DD[\s,\/\.\-]*(MM|MMM)|YYYY[\s,\/\.\-]*(MM|MMM)[\s,\/\.\-]*DD)$/
  return validDateFormatRegex.test(format)
}

function Date(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { questionEditorState } = nodeEditorStore
  const { promptSettings } = questionEditorState
  const { isUndoable, forceValue, trackResponse, inputDateFormat, displayDateFormat } =
    promptSettings

  const { settings } = useSettingsStore()
  const { dateFormat } = settings || {}
  const { inputFormat: defaultInputFormat, displayFormat: defaultDisplayFormat } = dateFormat || {}

  const [inputDateFormatError, setInputDateFormatError] = useState<string | null>(null)
  const [displayDateFormatError, setDisplayDateFormatError] = useState<string | null>(null)

  const handleChange = <K extends keyof IPromptSettings>(
    value: IPromptSettings[K],
    key: K
  ): void => {
    if (key === "inputDateFormat" && !isValidDateInputFormat(value as string)) {
      setInputDateFormatError("Invalid date format")
    } else if (key === "displayDateFormat" && !isValidDateDisplayFormat(value as string)) {
      setDisplayDateFormatError("Invalid date format")
    } else {
      setInputDateFormatError(null)
      setDisplayDateFormatError(null)
    }

    nodeEditorStore.updateState(EditorType.QUESTION, {
      ...questionEditorState,
      promptSettings: {
        ...questionEditorState.promptSettings,
        [key]: value
      }
    })
  }

  useEffect(
    () => {
      nodeEditorStore.updateState(EditorType.QUESTION, {
        ...questionEditorState,
        promptSettings: {
          ...questionEditorState.promptSettings,
          inputDateFormat: inputDateFormat || defaultInputFormat,
          displayDateFormat: displayDateFormat || defaultDisplayFormat
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={styles.datePickerContainer}>
      <FormGroup className={styles.checkboxFormGroup}>
        <FormControlLabel
          className={styles.formControlLabel}
          value="isUndoable"
          control={
            <Checkbox
              checked={isUndoable}
              onChange={(_event, value) => handleChange(value, "isUndoable")}
            />
          }
          label="Undoable"
          labelPlacement="start"
        />
        <FormControlLabel
          className={styles.formControlLabel}
          value="forceValue"
          control={
            <Checkbox
              checked={forceValue}
              onChange={(_event, value) => handleChange(value, "forceValue")}
            />
          }
          label="Force value"
          labelPlacement="start"
        />

        <FormControlLabel
          className={styles.formControlLabel}
          value="trackResponse"
          control={
            <Checkbox
              checked={trackResponse}
              onChange={(_event, value) => handleChange(value, "trackResponse")}
            />
          }
          label="Track response"
          labelPlacement="start"
        />

        <div>
          <FormGroup className={styles.formContainer}>
            <FormControl className={styles.formControl}>
              <TextField
                size="small"
                label="Input Format"
                name="inputFormat"
                className={styles.textField}
                placeholder="Input Format"
                value={inputDateFormat || ""}
                onChange={e => handleChange(e.target.value, "inputDateFormat")}
                error={!!inputDateFormatError}
                helperText={inputDateFormatError}
              />
            </FormControl>
            <FormControl className={styles.formControl}>
              <TextField
                size="small"
                label="Display Format"
                name="displayFormat"
                className={styles.textField}
                placeholder="Display Format"
                value={displayDateFormat || ""}
                onChange={e => handleChange(e.target.value, "displayDateFormat")}
                error={!!displayDateFormatError}
                helperText={displayDateFormatError}
              />
            </FormControl>
          </FormGroup>
        </div>
      </FormGroup>
    </div>
  )
}

export default Date
